/* Default styles (apply to all viewports) */
footer {
    width: 100%; /* Ensure footer takes full width */
    margin-top: 10px;
    border-top: 4px solid #00BF62; /* Adds a top border with the color #00BF62 */
    padding: 20px 0; /* Adjust padding as needed */
    background-color: #f8f9fa;
    box-sizing: border-box; /* Include padding and border in width calculation */
}

.container {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Ensure container takes full width */
    margin: 0 auto; /* Center container horizontally */
    padding: 0 20px; /* Optional: Padding to prevent content from touching edges */
    box-sizing: border-box; /* Include padding in width calculation */
}

.bio-service {
    color: #00BF62;
}

.footer-social--icons {
    display: flex;
    justify-content: space-around;
}

a {
    color: black;
    text-decoration: none;
}

/* Mobile view styles */
@media (max-width: 480px) {
    h1 {
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center; /* Center align text */
        font-size: 20px;
    }

    .container {
        flex-direction: column;
        padding: 0 10px; /* Reduce padding for smaller screens */
    }

    h2 {
        font-size: 20px;
    }

    .footer-advantage {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Corrected column distribution */
        margin: 10px;
        padding: 10px;
    }
}

/* Desktop view styles */
@media (min-width: 1024px) {
    footer {
        padding: 40px 0; /* Increase padding for desktop */
    }

    .container {
        padding: 0 40px; /* Increase padding to utilize more space */
        font-size: 1.125rem; /* Increase font size for desktop */
    }

    .bio-service {
        font-size: 2rem; /* Increase font size for desktop */
    }

    .footer-social--icons {
        font-size: 2rem; /* Increase icon size for desktop */
    }

    h1 {
        font-size: 2.5rem; /* Increase font size for desktop */
    }

    h2 {
        font-size: 2rem; /* Increase font size for h2 on desktop */
    }

    .footer-advantage {
        grid-template-columns: repeat(3, 1fr); /* Increase columns for desktop */
        margin: 20px;
        padding: 20px;
    }
}
